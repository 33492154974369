.buyer-add-supplier-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    width: 60vw;
    max-height: 95vh;
    display: flex;
    flex-direction: column;
    // padding: 24px;
    .buyer-add-supplier {
        padding-top: 8px;
        max-height: 70vh;
        overflow-y: scroll;
    }
}
.hidescrollbar::-webkit-scrollbar {
  display: none;
}