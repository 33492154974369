.image-preview-modal-content {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 10px;
    max-width:80vw;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.image-preview-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 24px;
    border-bottom: 1px solid #CBD5E1;
}
.image-preview-title-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #121A2D;
}
