.grouped-dropdown-container {
    width: 100%;
    text-align: left;
    border: 1px solid #E4EAF1;
    position: relative;
    border-radius: 5px;

    .grouped-dropdown-input {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        width: 100%;
    }

    .grouped-disabled-dropdown-selected-value {
        color: #E4EAF1;
    }

    .grouped-dropdown-filter-title {
        color: #64748B;
        font-size: 16px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.32px;
    }

    .grouped-dropdown-filter-title-selected {
        color: #3762FB;
        font-size: 16px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.32px;
    }

    .grouped-dropdown-selected-value {
        width: 100%;
        text-align: left;
    }

    .grouped-dropdown-menu {
        position: absolute;
        transform: translateY(4px);
        width: 200%;
        border: 1px solid #E4EAF1;
        border-radius: 5px;
        overflow: auto;
        max-height: 60vh;
        background-color: #fff;
        z-index: 2;
        padding: 16px;
        right: 0%;
    }
    .grouped-dropdown-option-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    .grouped-dropdown-option-header {
        color: #121A2D;
        font-size: 16px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.32px;
    }
    .grouped-dropdown-option-text {
        color: #121A2D;
        font-size: 14px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        line-height: 140%;
    }

    .grouped-dropdown-item {
        padding: 5px;
        cursor: pointer;
    }

    .grouped-grouped-dropdown-item:hover {
        background-color: #9fc3f870;
    }

    .grouped-dropdown-item.selected {
        background-color: #F1F5F9;
        color: #fff;
    }

    .grouped-dropdown-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .grouped-dropdown-tag-item {
        background-color: #ddd;
        padding: 2px 4px;
        border-radius: 2px;
        display: flex;
        align-items: center;
    }

    .grouped-dropdown-tag-close {
        display: flex;
        align-items: center;
    }

    .grouped-search-box {
        padding: 5px;
        background-color: #F1F5F9;
        position: sticky;
        top: 0;
    }

    .grouped-search-box input {
        width: 100%;
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;

    }
    .grouped-amount-range {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
