@import "../../../../styleHelpers/colors";
.title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 24px;
}
.payment-method {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121A2D;
}
.payment-method-details {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color:  $COLOR_GREY_500;
}
.processing-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 110px;
    height: 28px;
    background: #E8EDFF;
    border-radius: 34px;
}
.processing-status-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $COLOR_PRIMARY_500;
    margin-left: 6px;
    text-transform: capitalize;
}
.success-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 110px;
    height: 28px;
    background: $COLOR_SUCCESS_100;
    border-radius: 34px;
}
.success-status-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $COLOR_SUCCESS_500;
    margin-left: 6px;
    text-transform: capitalize;
}
.failed-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 110px;
    height: 28px;
    background: $COLOR_ERROR_100;
    border-radius: 34px;
}
.failed-status-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $COLOR_ERROR_500;
    margin-left: 6px;
    text-transform: capitalize;
}
.payment-type-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 32px;
    background: #F1F5F9;
    margin-right: 12px;
}