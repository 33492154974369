.app-multi-select-drop-error-text {
    margin: 0;
    margin-top: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.02em;
    padding: 0;
    color: #F04438;
    font-size: 0.6em;
    position: absolute;
    bottom: -12px;
}