.range-calender-success-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    width: 412px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    .range-calender-header {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }
    .range-date-container {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}