.supplier-jobs-virtualized-container {

  table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
  }

  tr {
    border-bottom: 1px solid #E4EAF1;
  }

  th {
    border-bottom: 1px solid #E4EAF1;
    padding: 4px;
    text-align: left;
  }

  td {
    padding: 2px 6px;
  }
}