.view-invoice-without-pdf {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    width: 60%;
    // height: 412px;
    display: flex;
    flex-direction: column;
    min-height: 50%;
}