.pdf-preview-modal-content {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 10px;
    max-width:80vw;
    display: flex;
    flex-direction: column;
    height: 93vh;
    overflow: auto;
}
.pdf-preview-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 24px;
    border-bottom: 1px solid #CBD5E1;
}
.pdf-preview-title-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #121A2D;
}
.preview-content {
    display: flex;
    flex-direction: row;
    padding: 24px;
}
.view-pdf-area {
    height: 75vh;
    width: 32vw;
    border: 1px solid #CBD5E1;
    flex-direction: column;
    display: 'flex';
}
.select-pdf-container {
    display: flex;
    flex-direction: column;
    width: 15vw;
    padding: 0px 24px;
    justify-content: space-between;
}
.select-pdf-title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121A2D;
    margin-bottom: 16px;
    width: 240px;
}
.preview-pdf-tile-label > input {
    visibility: hidden;
    position: absolute;
}
.preview-pdf-tile-label > input + div {
    cursor:pointer;
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid #CBD5E1;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 10px;
}
.preview-pdf-tile-label > input:checked + div {
    cursor:pointer;
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid #3762FB;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 10px;
}
.tile-radio-button {
    margin-right: 16px;
}
.preview-tile-radio-icon {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    border: #CBD5E1 1.5px solid;
}
.selected-preview-tile-radio-icon {
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    border: #3762FB 1.5px solid;
    justify-content: center;
    align-items: center;
}
.selected-preview-tile-radio-icon-check {
    height: 8px;
    width: 8px;
    background-color: #3762FB;
    border-radius: 4px;
}
.preview-sign-pdf > span {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: #64748B;
    margin-right: 4px;
}
.preview-sign-pdf {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: #121A2D;
}
.preview-newest-pdf {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    background: #F9FAFF;
    border: 1px solid #CDD9FF;
    border-radius: 4px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #3762FB;
    margin-top: 16px;
    width: 60px;
}
.preview-pdf-tile-title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121A2D;
    // margin-top: 16px;
}
.preview-form-select-container {
    display: flex;
    overflow: auto;
    flex-direction: column;
    height: 60vh;
}
.release-details-pdf-container {
    width: 300px;
    margin-left: 20px;
}
.release-details-container {
    margin-bottom: 12px;
}
.release-details-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
     margin-bottom: 12px;
}
.release-pdf-detail-header {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #64748B;
}
.release-pdf-detail-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121A2D;
}
.release-pdf-decline-reason-container {
    display: flex;
    background-color: #FEDF89;
    padding: 12px 16px;
    border-radius: 8px;
    flex-direction: row;
}
.release-pdf-decline-reason-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #93370D;
}