.dashboard-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    position: relative;

    .side-nav {
        width: 216px;
        height: 100vh;
        background: white;
        padding: 10px 12px;
        border-right: 1px solid #E4EAF1;

        .company-logo {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                width: auto;
                height: 30px;
                // height: auto;
                object-fit: contain;
                margin-right: 8px;
            }

            .company-name {
                font-weight: 700;
                font-size: 24px;
                line-height: 150%;
                color: #101218;
            }
        }

        .nav-list {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            height: 90%;

            .nav-item {
                height: 40px;
                margin: 8px 0;
                display: flex;
                align-items: center;
                padding: 5px;
                text-decoration: none;

                &__selected {
                    background: #F4F7FA;
                }

                img {
                    height: 20px;
                    margin: 10px 12px;
                }

                p {
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 170%;
                    // color: #101218;
                }
            }
        }

    }

    .main-section {
        // width: 100%;
        // width: 80vw;
        width: calc(100% - 216px);
        ;
        height: 100vh;
        background: #F4F7FA;

        .header {
            display: flex;
            justify-content: space-between;
            padding: 20px 32px;
            background: white;
            border-bottom: 1px solid #E4EAF1;

            .left-section {
                width: 200px;
                height: 50px;

                .page-title {
                    font-weight: 700;
                    color: #101218;
                }
            }

            .right-section {
                width: 200px;
                height: 50px;


            }
        }

        .details {
            width: 100%;
            height: 100%;
        }


    }

    .warning-pop-up {
        display: flex;
        position: absolute;
        padding: 20px;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        flex-direction: column;

        .warning-text {
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            color: #121A2D;
            margin-top: 10px;
        }
    }

    .select-account-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px;
        gap: 4px;
        width: 90px;
        height: 28px;
        background: #E8EDFF;
        border-radius: 34px;
        justify-content: space-between;
    }

    .select-account-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #3762FB;
    }

    .account-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #000000;
    }

    .account-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px;
        gap: 4px;
        width: 90px;
        height: 28px;
        background: #FFFFFF;
        justify-content: space-between;
    }

    .account-text:hover {
        color: #3762FB;
    }

    .drop-down {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        background: #FFFFFF;
        border: 1px solid #E4EAF1;
        border-radius: 8px;

        .drop-down-title {
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            font-weight: 500;
            // font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #64748B;
            text-transform: capitalize;
        }
    }

    .drop-down-option {
        display: flex;
        padding: 8px 16px;
        background: #F4F7FA;
        border-radius: 4px;
        width: 100%;
        margin-top: 8px;
    }

    .selected-drop-down-option {
        display: flex;
        padding: 8px 16px;
        background: #3762FB;
        border-radius: 4px;
        width: 100%;
        margin-top: 8px;
    }

    .drop-down-option-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 170%;
        color: #121A2D;
        text-transform: capitalize;
    }

    .selected-drop-down-option-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 170%;
        color: #FFFFFF;
        text-transform: capitalize;
    }

    .help-button {
        display: flex;
        flex-direction: row;
        background: #FFFAEB;
        // width: 100%;
        padding: 8px 16px;
        border-radius: 30px;
    }

    .help-text {
        margin-left: 10px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 170%;
        color: #000000;
    }
}