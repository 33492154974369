.select-wrapper {
    width: 100%;

    fieldset {
        border-width: 0px;
    }

    .select-input {
        width: 100%;
        padding: 0px 1px;
        font-size: 0.8em;
        border-width: 0;
        outline: none;

        &:nth-child(1) {
            box-shadow: 0 0 0 0px #ffffff;
            outline: none;

            &:hover {
                box-shadow: 0 0 0 0px #ffffff;
                border-radius: 8px;
            }
        }



        &>div {
            // border: 1px solid #E4EAF1;
            outline: none;
            border-radius: 10px;
            padding-top: 1.8px;
            padding-bottom: 1.8px;
        }
    }

}

.select-wrapper__error {
    width: 100%;

    fieldset {
        border-width: 0px;
    }

    .select-input {
        width: 100%;
        padding: 0px 1px;
        font-size: 0.7em;
        border-width: 0;
        outline: none;

        &:nth-child(1) {
            box-shadow: 0 0 0 0px #ffffff;
            outline: none;

            &:hover {
                box-shadow: 0 0 0 0px #ffffff;
                border-radius: 8px;
            }
        }



        &>div {
            border: 1px solid red;
            outline: none;
            border-radius: 10px;
        }
    }

}