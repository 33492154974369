.add-payment-method-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    width: 35vw;
    // max-width: 900px;
    max-height: 85vh;
    display: flex;
    flex-direction: column;
    // padding: 15px 24px;
    .payment-content-title {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
        margin-bottom: 24px;
        margin-top: 24px;
    }
    .payment-type-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 40px;
        background: #F1F5F9;
        border-radius: 4px;
        margin-left: 16px;
    }
}