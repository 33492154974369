.create-release-wrapper {
    width: 100%;
    .create-release-wrapper-title {
        color: #121A2D;
        font-family: 'Plus Jakarta Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.36px;
    }
}