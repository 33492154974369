.quick-books-auth-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    // width: 30vw;
    width: 432px;
    min-height: 417px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 34px;
}