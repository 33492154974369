@import "../../../styleHelpers/colors";
.dwolla-customer-dropin-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    width: 512px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    .dropInContainer {
        display: flex;
        justify-content: center;
        padding: 24px;
        overflow-y: scroll;
        max-height: 80vh;
    }
    .drop-in-modal-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 8px 24px;
        border-bottom: 1px solid #CBD5E1;
    }
    .modal-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 8px 24px;
    }
    .email-input {
        display: flex;
        width: 100%;
        border: #CBD5E1 1px solid;
        height: 40px;
        border-radius: 4px;
        padding-left: 4px;
        padding-right: 4px;
    }
    .email-button {
        display: flex;
        width: 100%;
        height: 40px;
        margin-top: 16px;
        background-color: #3762FB;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-weight: 600;
    }
    .loadingImage {
        display: flex;
        height: 100px;
        width: 100px;
        align-self: center;
        border-radius: 50px;
        animation: spin 2s infinite linear;
        background-color: #EDF7FE;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }
    .details-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }
    .success-image {
        display: flex;
        height: 100px;
        width: 100px;
        align-self: center;
        border-radius: 50px;
        background-color: #D1FADF;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }
     .errorImage {
        display: flex;
        height: 100px;
        width: 100px;
        align-self: center;
        border-radius: 50px;
        background-color: #FEF0EF;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }
    .failed-desc-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #64748B;
        margin-bottom: 16px;
    }
    .failed-desc-text > span {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: $COLOR_ERROR_500;;
    }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}