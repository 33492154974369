.error-page-error-title {
    color:  #121A2D;
    font-size: 18px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.48px;
}
.error-page-error-text {
    color: #64748B;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    line-height: 140%;
    letter-spacing: -0.32px;
    margin-top: 12px;
}