.quick-books-connect-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    // width: 30vw;
    width: 412px;
    min-height: 412px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    .title-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
        margin-bottom: 8px;
    }
    .subtitle-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #64748B;
    }
    .additional-info {
        display: flex;
        background-color: #F1F5F9;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 10px;
        border-radius: 8px;
        margin-top: 8px;
        width: 100%;
        list-style: disc;
        align-content: flex-start;
        padding-left: 32px;
    }
    .additional-info-item {
        text-align: left;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: #121A2D;
    }
}