.custom-dropdown-container {
  text-align: left;
  position: relative;

  .custom-disabled-dropdown-selected-value {
    color: #e4eaf1;
  }

  .custom-dropdown-menu {
    position: absolute;
    transform: translateY(4px);
    border: 1px solid #e4eaf1;
    border-radius: 5px;
    overflow: auto;
    max-height: 600px;
    background-color: #fff;
    z-index: 2;
    // padding: 12px;
    right: 0%;
    background-color: #f9faff;
  }
}
