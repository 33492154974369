@import "./styleHelpers/generalModalStyles.scss";
@import "./styleHelpers/colors.scss";

* {
  box-sizing: border-box;
  letter-spacing: -0.02em;

  &:focus {
    outline: none;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  font-family: 'Plus Jakarta Sans';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.text-no-wrap {
  white-space: nowrap;
}

.word-wrap {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-word;
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.react-datepicker-wrapper {
  width: 100%;
}

.loadingImage {
  display: flex;
  height: 100px;
  width: 100px;
  align-self: center;
  border-radius: 50px;
  animation: spin 2s infinite linear;
  background-color: #EDF7FE;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}