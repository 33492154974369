.video-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    .video {
        border-radius: 10px;
        overflow: hidden;
    }
}
.close-button {
    position: absolute;
    top: -30px;
    right: -30px;
    z-index: 10;
}