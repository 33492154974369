.app-payment-invoice-table-wrapper {
    .create-release-info {
        display: flex;
        flex-direction: row;
        background-color: #B9E6FE;
        padding: 16px 24px;
        border-radius: 8px;
        margin-top: 12px;
    }
    .create-release-info-text {
        color:  #065986;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.28px;
    }
    .create-release-error {
        display: flex;
        flex-direction: row;
        background-color: #FEF0EF;
        padding: 16px 24px;
        border-radius: 8px;
        align-items: center;
    }
    .create-release-error-info {
        color:  #F04438;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.28px;
    }
    .create-release-error-info > span {
        font-weight: 600;
    }
    .create-release-info-text > span {
        font-weight: 600;
    }
    .create-release-info-text > a {
        text-decoration-line: underline;
        cursor: pointer;
    }
    .payment-invoice-table-modal-left-content {
        width: 600px;
    }
    .payment-invoice-table-modal-right-content {
        width: 500px;
    }
    .edit-invoice-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #E4EAF1;
        border-radius: 8px;
        padding: 12px 16px;
    }
    .edit-invoice-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #E4EAF1;
        border-radius: 8px;
        padding: 12px 4px;
    }
    .payment-invoice-table-modal-container {
        overflow: scroll;
        max-height: 85vh;
    }
}