  // primary
  $COLOR_PRIMARY_50: #F9FAFF;
  $COLOR_PRIMARY_100: #E8EDFF;
  $COLOR_PRIMARY_200: #CDD9FF;
  $COLOR_PRIMARY_300: #96B0FF;
  $COLOR_PRIMARY_400: #5982FF;
  $COLOR_PRIMARY_500: #3762FB;
  $COLOR_PRIMARY_600: #3158E2;
  $COLOR_PRIMARY_700: #2847B7;
  $COLOR_PRIMARY_800: #2440A5;
  $COLOR_PRIMARY_900: #1D3485;
  // secondary
  $COLOR_SECONDARY_50: #F6F7FC;
  $COLOR_SECONDARY_100: #EFF2FA;
  $COLOR_SECONDARY_200: #A8ADD3;
  $COLOR_SECONDARY_300: #7177AE;
  $COLOR_SECONDARY_400: #454B89;
  $COLOR_SECONDARY_500: #303778;
  $COLOR_SECONDARY_600: #1D2675;
  $COLOR_SECONDARY_700: #081371;
  $COLOR_SECONDARY_800: #000954;
  $COLOR_SECONDARY_900: #000534;
  // error
  $COLOR_ERROR_100: #FEF0EF;
  $COLOR_ERROR_200: #FEE4E2;
  $COLOR_ERROR_300: #FDA29B;
  $COLOR_ERROR_400: #F97066;
  $COLOR_ERROR_500: #F04438;
  $COLOR_ERROR_600: #D92D20;
  $COLOR_ERROR_700: #B42318;
  $COLOR_ERROR_800: #912018;
  $COLOR_ERROR_900: #7A271A;
  // warning
  $COLOR_WARNING_100: #FFFAEB;
  $COLOR_WARNING_200: #FEF0C7;
  $COLOR_WARNING_300: #FEDF89;
  $COLOR_WARNING_400: #FEC84B;
  $COLOR_WARNING_500: #FDB022;
  $COLOR_WARNING_600: #F79009;
  $COLOR_WARNING_700: #DC6803;
  $COLOR_WARNING_800: #DC6803;
  $COLOR_WARNING_900: #93370D;
  // success
  $COLOR_SUCCESS_100: #ECFDF3;
  $COLOR_SUCCESS_200: #D1FADF;
  $COLOR_SUCCESS_300: #A6F4C5;
  $COLOR_SUCCESS_400: #6CE9A6;
  $COLOR_SUCCESS_500: #32D583;
  $COLOR_SUCCESS_600: #12B76A;
  $COLOR_SUCCESS_700: #027A48;
  $COLOR_SUCCESS_800: #05603A;
  $COLOR_SUCCESS_900: #054F31;
  // info
  $COLOR_INFO_100: #EDF7FE;
  $COLOR_INFO_200: #E0F2FE;
  $COLOR_INFO_300: #B9E6FE;
  $COLOR_INFO_400: #7CD4FD;
  $COLOR_INFO_500: #36BFFA;
  $COLOR_INFO_600: #0BA5EC;
  $COLOR_INFO_700: #0086C9;
  $COLOR_INFO_800: #1769AA;
  $COLOR_INFO_900: #065986;
  // grey
  $COLOR_GREY_50: #F8FAFC;
  $COLOR_GREY_100: #F1F5F9;
  $COLOR_GREY_200: #E4EAF1;
  $COLOR_GREY_300: #CBD5E1;
  $COLOR_GREY_400: #94A3B8;
  $COLOR_GREY_500: #64748B;
  $COLOR_GREY_600: #475569;
  $COLOR_GREY_700: #334155;
  $COLOR_GREY_800: #1E293B;
  $COLOR_GREY_900: #101218;
  // grey
  $COLOR_TEXT_PRIMARY: #121A2D;
  $COLOR_TEXT_SECONDARY: #64748B;
  $COLOR_TEXT_TERTIARY: #7A7A7A;
  $COLOR_TEXT_ACCENT: #AAAAAA;
  $COLOR_WHITE: #FFFFFF;




  $color-map: (
    PRIMARY_50:$COLOR_PRIMARY_50,
    PRIMARY_100:$COLOR_PRIMARY_100,
    PRIMARY_200:$COLOR_PRIMARY_200,
    PRIMARY_300: $COLOR_PRIMARY_300,
    PRIMARY_400: $COLOR_PRIMARY_400,
    PRIMARY_500: $COLOR_PRIMARY_500,
    PRIMARY_600:$COLOR_PRIMARY_600,
    PRIMARY_700: $COLOR_PRIMARY_700,
    PRIMARY_800: $COLOR_PRIMARY_800,
    PRIMARY_900: $COLOR_PRIMARY_900,
    SECONDARY_50: $COLOR_SECONDARY_50,
    SECONDARY_100: $COLOR_SECONDARY_100,
    SECONDARY_200: $COLOR_SECONDARY_200,
    SECONDARY_300: $COLOR_SECONDARY_300,
    SECONDARY_400: $COLOR_SECONDARY_400,
    SECONDARY_500: $COLOR_SECONDARY_500,
    SECONDARY_600: $COLOR_SECONDARY_600,
    SECONDARY_700: $COLOR_SECONDARY_700,
    SECONDARY_800: $COLOR_SECONDARY_800,
    SECONDARY_900: $COLOR_SECONDARY_900,
    ERROR_100: $COLOR_ERROR_100,
    ERROR_200: $COLOR_ERROR_200,
    ERROR_300: $COLOR_ERROR_300,
    ERROR_400: $COLOR_ERROR_400,
    ERROR_500: $COLOR_ERROR_500,
    ERROR_600: $COLOR_ERROR_600,
    ERROR_700: $COLOR_ERROR_700,
    ERROR_800: $COLOR_ERROR_800 ,
    ERROR_900:$COLOR_ERROR_900,
    WARNING_100: $COLOR_WARNING_100,
    WARNING_200: $COLOR_WARNING_200,
    WARNING_300: $COLOR_WARNING_300,
    WARNING_400: $COLOR_WARNING_400,
    WARNING_500: $COLOR_WARNING_500,
    WARNING_600: $COLOR_WARNING_600,
    WARNING_700: $COLOR_WARNING_700,
    WARNING_800: $COLOR_WARNING_800,
    WARNING_900: $COLOR_WARNING_900,
    SUCCESS_100: $COLOR_SUCCESS_100,
    SUCCESS_200: $COLOR_SUCCESS_200,
    SUCCESS_300: $COLOR_SUCCESS_300,
    SUCCESS_400: $COLOR_SUCCESS_400,
    SUCCESS_500: $COLOR_SUCCESS_500,
    SUCCESS_600: $COLOR_SUCCESS_600,
    SUCCESS_700: $COLOR_SUCCESS_700,
    SUCCESS_800: $COLOR_SUCCESS_800,
    SUCCESS_900: $COLOR_SUCCESS_900,
    INFO_100: $COLOR_INFO_100,
    INFO_200: $COLOR_INFO_200,
    INFO_300: $COLOR_INFO_300,
    INFO_400: $COLOR_INFO_400,
    INFO_500: $COLOR_INFO_500,
    INFO_600: $COLOR_INFO_600,
    INFO_700: $COLOR_INFO_700,
    INFO_800: $COLOR_INFO_800,
    INFO_900: $COLOR_INFO_900,
    GREY_50: $COLOR_GREY_50,
    GREY_100: $COLOR_GREY_100,
    GREY_200: $COLOR_GREY_200,
    GREY_300: $COLOR_GREY_300,
    GREY_400: $COLOR_GREY_400,
    GREY_500: $COLOR_GREY_500,
    GREY_600: $COLOR_GREY_600,
    GREY_700: $COLOR_GREY_700 ,
    GREY_800:$COLOR_GREY_800,
    GREY_900: $COLOR_GREY_900,
    TEXT_PRIMARY: $COLOR_TEXT_PRIMARY,
    TEXT_SECONDARY: $COLOR_TEXT_SECONDARY,
    TEXT_TERTIARY: $COLOR_TEXT_TERTIARY,
    TEXT_ACCENT: $COLOR_TEXT_ACCENT,
    WHITE: $COLOR_WHITE
  );

  @each $name,
  $color in $color-map {
    .#{$name}-BG {
      background: $color;
    }

    .#{$name}-CLR {
      color: $color;
    }

    .#{$name}-BORDER {
      border-color: $color;
    }

  }