.progress-spinner-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #F8FAFC, $alpha: 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}