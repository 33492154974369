.signature-txt-conatainer {
  background-color: #FFFFFF;
  align-items: center;
  padding: 20px;
  height: 170px;
  margin-top: 20px;
  border-radius: 24px;
  overflow-x: scroll;
  white-space: nowrap;
}
.signature-txt {
  // padding: 4px;
  font-family: 'september-spirit';
  display: inline-block;
  font-size: 60px;
  background-color: transparent;
}
.sign-detail-spacer {
  margin-top: 20px;
}
.hide-signature-canvas {
  display: none;
}
