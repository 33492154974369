.confirm-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    width: 412px;
    min-height: 212px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;

    img {
        width: 110px;
        object-fit: contain;
    }

    .modal-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        margin-bottom: 16px;
    }

    .modal-body {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 16px;
        text-align: center;
        color: #64748B;
    }

    .action-buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }


}