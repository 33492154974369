.verify-business-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    width: 562px;
    // height: 412px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // padding: 24px;
    .drop-in-modal-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 24px;
        border-bottom: 1px solid #CBD5E1;
    }
    .verify-busine-modal-title {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #121A2D;
    }
    .row-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .progress-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 8px 24px;
    }
    .progress-item {
        margin-right: 4px;
        width: 230px;
    }
    .finsh-item {
        margin-right: 4px;
        width: 60px;
    }
    .line {
        display: flex;
        width: 100%;
        height: 1px;
        background-color: #E4EAF1;
    }
    .verify-business-content {
        margin: 24px;
    }
    .business-message-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px;
        background: #F1F5F9;
        border-radius: 10px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #121A2D;
    }
    .spacer {
        width: 10px;
    }
    .step-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: #64748B;
        margin-top: 8px;
    }
    .verified-progress-item {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #32D583;
    }
    .current-progress-item {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #121A2D;
    }
    .next-progress-item {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #64748B;
    }
    .checked-circle {
        position: relative;
        display: flex;
        margin-right: 12px;
        height: 18px;
        width: 18px;
        background-color: #32D583;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
    }
    .unchecked-circle {
        display: flex;
        margin-right: 12px;
        height: 18px;
        width: 18px;
        background-color: #CBD5E1;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
    }
    .action-buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        border-top: 1px solid #CBD5E1;
    }
    .verify-text-header {font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #121A2D;
    }
    .finished-verify-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #64748B;
        margin-top: 10px;
    }
}