.dropdown2-container {
    width: 100%;
    text-align: left;
    // border: 1px solid #E4EAF1;
    position: relative;
    border-radius: 8px;

    .dropdown-input {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        width: 100%;
    }

    .disabled-dropdown-selected-value {
        color: #E4EAF1;
    }

    .dropdown-selected-value {
        width: 100%;
        text-align: left;
    }

    .dropdown2-menu {
        position: absolute;
        transform: translateY(8px);
        width: 100%;
        border: 1px solid #E4EAF1;
        border-radius: 5px;
        background-color: #fff;
        z-index: 2;
    }

    .dropdown2-menu-list {
        width: 100%;
        overflow: auto;
        max-height: 240px;
        background-color: #fff;
    }

    .dropdown2-item {
        padding: 5px;
        cursor: pointer;
    }

    .dropdown2-item:hover {
        background-color: #9fc3f870;
        color: #000;
    }

    .dropdown2-item.selected {
        background-color: #F1F5F9;
        color: #fff;
    }

    .dropdown-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .dropdown-tag-item {
        background-color: #ddd;
        padding: 2px 4px;
        border-radius: 2px;
        display: flex;
        align-items: center;
    }

    .dropdown-tag-close {
        display: flex;
        align-items: center;
    }

    .search-box2 {
        padding: 5px;
        background-color: #F1F5F9;
        position: sticky;
        top: 0;
    }

    .search-box2 input {
        width: 100%;
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 12px;
    }
}



// .select-test-wrapper {
//     width: 100%;
//     position: relative;
//     height: 40px;


//     input {
//         height: 40px;
//         width: 100%;
//         border: 1px solid #E4EAF1;
//         border-radius: 8px;
//         padding: 10px 8px;
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         font-size: 0.7em;
//     }

//     .drop-down-section {
//         position: absolute;
//         // display: none;
//         top: 40px;
//         background-color: white;
//         border-radius: 0 0 5px 5px;
//         font-family: sans-serif;
//         width: 100%;
//         padding: 0 5px;
//         max-height: 10rem;
//         z-index: 1000;
//         overflow-y: auto;
//         box-shadow: 0px 10px 10px rgba(0, 39, 67, 0.1);
//         border-radius: 10px;

//         .search-section {
//             background-color: white;
//             border: 1px solid #E4EAF1;
//             position: sticky;
//             top: 0;
//         }
//     }

//     ul {
//         padding: 0 10px;
//     }

//     fieldset {
//         border-width: 0px;
//     }

//     .chip-custom-menu {
//         padding: 5px;
//     }

//     .select-input {
//         width: 100%;
//         padding: 0px 1px;
//         font-size: 0.7em;
//         z-index: 1000;

//         &:nth-child(1) {
//             box-shadow: 0 0 0 0px #ffffff;
//             // border: 1px solid #E4EAF1;
//             border-radius: 8px;

//             &:hover {
//                 box-shadow: 0 0 0 0px #ffffff;
//                 border: 1px solid #E4EAF1;
//                 border-radius: 8px;
//             }
//         }



//         &>div {
//             border-width: 0;
//             outline: none;
//         }
//     }

// }