@import "../../../../styleHelpers/colors";

.title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #000000;
    // margin-bottom: 24px;
}

.payment-container {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 82vw;
}

.payment-item-container {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    padding: 14px;
    gap: 10px;
    width: 278px;
    min-height: 160px;
    background: #FFFFFF;
    border: 1px solid #E4EAF1;
    border-radius: 10px;
    margin-right: 16px;
}

.card-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 16px;
    width: 64px;
    height: 40px;
    background: #F1F5F9;
    border-radius: 4px;
}

.card-title {
    font-weight: 600;
    font-size: 16px;
    color: #121A2D;
    width: 230px;
}

.exp-card-date {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #64748B;
}

// .edit-button {

// }