.schedule-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    // width: 30vw;
    width: 500px;
    max-height: 45vh;
    display: flex;
    flex-direction: column;
    // overflow: hidden;

    .schedule-modal-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-self: center;
        width: 100%;
        padding: 8px 24px;
        border-bottom: 1px solid #CBD5E1;
    }
    .schedule-modal-title-header {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #121A2D;
    }
   .content {
        padding: 12px 32px;
   }
   .timezone-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        margin-bottom: 16px;
        color: #121A2D;
   }
   .details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        background: #F9FAFF;
        border-radius: 8px;
   }
   .date-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */

        letter-spacing: -0.02em;

        /* Text/Primary */

        color: #121A2D;
        margin-bottom: 8px;
   }
   .instruction-text {
        font-family: 'Plus Jakarta Sans';
        font-style: italic;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #121A2D;
   }
   .instruction-text > span {
        font-family: 'Plus Jakarta Sans';
        font-style: italic;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #5982FF;
   }
   .select-day-container {
        display: flex;
        flex-direction: row;
   }
   .date-picker-container {
        width: 300px;
        margin-right: 10px;
   }
}
