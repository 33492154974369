.forward-release-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    width: 640px;
    min-height: 212px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // padding: 24px;
    .action-buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }
    .error-text {
        margin: 0;
        margin-top: 3px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.02em;
        padding: 0;
        color: red;
        font-size: 0.6em;
    }


}