@import "../../../../styleHelpers/colors";

.title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 24px;
}

.summary-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    min-width: 50px;
    height: 158px;
    background: #FFFFFF;
    border: 1px solid #E4EAF1;
    border-radius: 10px;
    margin-bottom: 24px;
}

.summary-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 40px;
    height: 40px;
    background: #F1F5F9;
    border-radius: 4px;
    margin-bottom: 24px;
}

.summary-amount {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #121A2D;
}
.withdrawal-container {
    height: 58vh;
}