.pdf-dowload-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 30vw;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow: auto;
}
.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid #CBD5E1;
}
.title-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #121A2D;
}
.content {
    padding: 24px;
    border-bottom: 1px solid #CBD5E1;
}
.tile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border: 1px solid #CBD5E1;
    border-radius: 10px;
    padding: 16px;
    justify-content: space-between;
}
.tile-title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121A2D;
}
.tile-icon {
    display: flex;
    padding: 8px;
    background-color: #F1F5F9;
    margin-right: 8px;
}
.tile-label > input {
    visibility: hidden;
    position: absolute;
}
.tile-label > input + div {
    cursor:pointer;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border: 1px solid #CBD5E1;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 10px;
}
.tile-label > input:checked + div {
    cursor:pointer;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border: 1px solid #3762FB;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 10px;
}
.tile-radio-button {
    margin-right: 16px;
}
.tile-radio-icon {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    border: #CBD5E1 1.5px solid;
}
.selected-tile-radio-icon {
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    border: #3762FB 1.5px solid;
    justify-content: center;
    align-items: center;
}
.selected-tile-radio-icon-check {
    height: 8px;
    width: 8px;
    background-color: #3762FB;
    border-radius: 4px;
}
.content-title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121A2D;
    margin-bottom: 16px;
}
.action-buttons {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    padding: 24px;
}
.last-update > span {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: #64748B;
    margin-right: 4px;
}
.last-update {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: #121A2D;
}
.newest-pdf {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background: #F9FAFF;
    border: 1px solid #CDD9FF;
    border-radius: 4px;
    float: right;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #3762FB;
}
.tile-content {
    display: flex;
    justify-content: center;
    align-items: center;
}