.signature-success-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    width: 412px;
    min-height: 212px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    .successImage {
        display: flex;
        height: 100px;
        width: 100px;
        align-self: center;
        border-radius: 50px;
        background-color: #D1FADF;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
        margin-top: 48px;
    }
    .signature-header {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }
    .header-description {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #64748B;
        padding-left: 50px;
        padding-right: 50px;
    }
    .details {
        display: flex;
        background: #F1F5F9;
        border-radius: 8px;
        width: 100%;
        justify-content: space-between;
        margin-top: 32px;
        margin-bottom: 45px;
    }
    .side-details {
        width: 50%;
        padding: 16px;
    }
    .details-side-header {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #64748B;
        margin-bottom: 4px;
    }
    .details-side-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #121A2D;
        margin-bottom: 16px;
    }
}