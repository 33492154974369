.supplier-withdraw-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    // width: 30vw;
    max-width: 80vw;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    // padding: 15px 24px;

    .loadingImage {
        display: flex;
        height: 100px;
        width: 100px;
        align-self: center;
        border-radius: 50px;
        animation: spin 2s infinite linear;
        background-color: #EDF7FE;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }
    .payment-type-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 40px;
        background: #F1F5F9;
        border-radius: 4px;
        margin-left: 16px;
    }
}