.drop-zone {
  display: flex;
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
}

.drop-zone.active {
  border-color: #3762FB;
}

.drop-zone p {
  margin: 0;
}

.drop-zone:hover {
  border-color: #3762FB;
}

.drop-zone input {
  display: none;
}
.drop-zone-remove-item-text {
  color: #F97066;
  text-align: right;
  font-size: 14px;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.28px;
}
.drop-zone-list-file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.drop-zone-text-file-upload {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  font-size: 0.6em;
}
