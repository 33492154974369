.invoices-table-wrapper {
    // width: 100%;
    // height: 100%;


    border-radius: 10px;
    // overflow: hidden;

    .invoices-table {

        // width: 200%;
        height: 100%;
        width: max-content;
        width: auto;
        height: 90%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        border: 1px solid #E4EAF1;
        overflow-x: scroll;

        .invoice-cell {
            height: 100%;
            width: 100%;
            margin: 0 5px;
            padding: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            p {
                margin: 0;
                // color: #101218;
                font-weight: 500;
                font-size: 12px;
                line-height: 140%;
            }
        }

        &__header {
            width: max-content;

            display: flex;
            border-bottom: 1px solid #E4EAF1;
            padding: 0 5px;
            top: 0;
            z-index: 20;
            background: #ffffff;
            position: sticky;

            .header-cell {
                height: 48px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                p {
                    margin: 0;
                    color: #64748B;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 140%;
                }
            }
        }

        &__main {
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;

            .error-row {
                background: #FEF0EF;
            }

            .invoice-row {
                width: max-content;
                display: flex;
                margin: 4px 0;
                padding: 4px 5px;
                padding-bottom: 12px;

                img {
                    width: 20px;
                    height: 20px;
                }


            }

            .empty-list {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &>div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                img {
                    width: 321px;
                    object-fit: contain;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 120%;
                    color: #64748B;
                }
            }
        }

        &__footer {
            border-top: 1px solid #E4EAF1;
            background: #F1F5F9;
            display: flex;
            align-items: center;

            .footer-button {
                margin-left: 20px;
                padding: 4px 0;
            }

            p {
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                color: #101218;

            }
        }
    }
}

.name-container {
    display: flex;
    width: '100%';
    flex-direction: row;
    align-items: center;
}
.alert-icon {
    height: 16px;
    width: 16px;
    margin-left: 8px;
}
.tooltip-trigger .tooltip{
    opacity: 0;
}
.tooltip-trigger:hover .tooltip {
    opacity:1;
}
.tooltip-trigger {
    position: relative;
    display: inline;
}
.tooltip-trigger .tooltip{
    width: 180px;
    background-color: #F04438;
    color: #FFFFFF;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    z-index: 1;
    left:-73px;
    top: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.tooltip-trigger .tooltip-bottom::after {
    content: " ";
    position: absolute;
    bottom: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #F04438 transparent;
}
.eye-icon {
    height: 18px;
    width: 18px;
}
.preview-button {
    padding: 8px;
    background-color: #F9FAFF;
    margin-right: 8px;
    border: #E4EAF1 solid 1px;
    border-radius: 10px;
    height: 36px;
    width: 36px;
    justify-content: center;
    align-items: center;
    align-self: center;
}