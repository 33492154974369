.button-wrapper {
    background: #F9FAFF;
    border: 2px solid #3762FB;
    border-radius: 10px;
    padding: 10px 20px;
    color: #3762FB;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    &__filled {}

    button {
        // cursor: default;
    }


    .button-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .button-text {
            margin: 0 5px;
            font-weight: 500;

            line-height: 140%;
            white-space: nowrap;
        }

        .spinner {
            border: 3px solid rgba(0, 0, 0, 0.1);
            border-top-color: #3498db;
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            animation: spin 1s ease-in-out infinite;
        }
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}