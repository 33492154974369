@import "../../../styleHelpers/colors";

.invoice-select-pay-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 10px;
    max-width: 680px;
    display: flex;
    flex-direction: column;

    .payment-modal-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2px 24px;
        border-bottom: 1px solid #CBD5E1;
    }

    .paid-invoice-modal-list-content {
        width: 680px;
        max-height: 70vh;
        overflow: scroll;
        padding-bottom: 4px;
    }

    .process-content {
        width: 400px;
    }

    .paid-invoice-modal-title {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #121A2D;
    }

    .payment-content-title {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
        margin-bottom: 8px;
    }

    .payment-content-subtitle {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #64748B;
    }

    .edit-invoice-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #E4EAF1;
        border-radius: 8px;
        padding: 12px 16px;
    }

    .edit-invoice-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #E4EAF1;
        border-radius: 8px;
        padding: 12px 4px;
    }

    .payment-type-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 40px;
        background: #F1F5F9;
        border-radius: 4px;
        margin-left: 16px;
    }

    .detailText {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #64748B;
        text-transform: capitalize;
        width: 100px;
    }

    .respText {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #121A2D;
        text-transform: capitalize;
        margin-left: 8px;
        width: 100%;
    }

    .amountText {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #3762FB;
    }

    .totalText {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #3762FB;
    }

    .accountDetails {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #64748B;
    }

    .rowContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .processing-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px;
        width: 110px;
        height: 28px;
        background: #E8EDFF;
        border-radius: 34px;
    }

    .processing-status-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.01em;
        color: $COLOR_PRIMARY_500;
        margin-left: 6px;
        text-transform: capitalize;
    }

    .success-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px;
        width: 110px;
        height: 28px;
        background: $COLOR_SUCCESS_100;
        border-radius: 34px;
    }

    .success-status-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.01em;
        color: $COLOR_SUCCESS_500;
        margin-left: 6px;
        text-transform: capitalize;
    }

    .failed-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px;
        width: 110px;
        height: 28px;
        background: $COLOR_ERROR_100;
        border-radius: 34px;
    }

    .failed-status-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.01em;
        color: $COLOR_ERROR_500;
        margin-left: 6px;
        text-transform: capitalize;
    }

    .failed-desc-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        padding: 24px;
        gap: 40px;
        background: #FEF0EF;
        border-radius: 10px;
        margin: 16px;
    }

    .failed-desc-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #64748B;
    }

    .failed-desc-text>span {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: $COLOR_ERROR_500;
        ;
    }

    .withdrawal-date {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #64748B;
    }

    .bank-detail-number {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: #64748B;
        text-transform: capitalize;
    }
}