@import '../../../styleHelpers/colors';

.new-job-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  width: 540px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;

  .new-job-modal-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 24px;
    border-bottom: 1px solid #cbd5e1;
  }

  .content-job {
    padding-top: 8px;
    max-height: 70vh;
    overflow-y: auto;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    padding: 2px 24px;
    margin-bottom: 8px;
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    padding: 2px 24px;
    margin-bottom: 8px;
    align-items: center;
  }

  .input-label {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121a2d;
    margin-bottom: 4px;
  }

  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    // width: '100%';
    height: 40px;
    background: #ffffff;
    border: 1px solid #e4eaf1;
    border-radius: 8px;
    margin-top: 4px;
  }

  .input-error {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #f04438;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    padding: 2px 24px;
  }

  .input-row-container {
    display: flex;
    flex-direction: column;
    // margin-bottom: 8px;
    width: 145px;
  }

  .spacer {
    display: flex;
    width: 24px;
    height: 16px;
  }

  .new-job-label {
    color: #121a2d;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  .new-job-label>span {
    color: #f04438;
  }
}