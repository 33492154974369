@import '../../../../styleHelpers/colors';
.title {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 24px;
}
.help-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #e4eaf1;
  border-radius: 8px;
  margin-bottom: 16px;
  .video {
    position: relative;
    width: 320px;
    height: 160px;
    border-radius: 8px;
    margin-top: 18px;
    overflow: hidden;
  }
  .video > iframe {
    pointer-events: none;
  }
  .detail-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121a2d;
    align-self: center;
  }
  .detail-text > span {
    color: #3762fb;
  }
  .sub-text {
    text-align: left;
  }
  .help-circle {
    padding: 10px;
    background: #fffaeb;
    border-radius: 60px;
  }
  .play-button {
    display: flex;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 45%;
    top: 40%;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    justify-content: center;
    align-items: center;
  }
}
.row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
