.app-invoice-select-pay-modal-content {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    // bgcolor: 'background.paper';
    // boxShadow: 24;
    border-radius: 10px;
    // width: 30vw;
    // max-width: 500px;
    // max-height: 45vh;
    display: flex;
    flex-direction: column;
    // overflow: hidden;

    // padding: 15px 24px;
    .payment-modal-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-self: center;
        width: 100%;
        padding: 4px 24px;
        border-bottom: 1px solid #CBD5E1;
    }
    .new-payment-method-select-modal {
        width: 500px;
    }

    .process-content {
        width: 400px;
    }
    .payment-loading-modal {
        width: 400px;
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .loadingImage {
        display: flex;
        height: 100px;
        width: 100px;
        align-self: center;
        border-radius: 50px;
        animation: spin 2s infinite linear;
        background-color: #EDF7FE;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }

    .errorImage {
        display: flex;
        height: 100px;
        width: 100px;
        align-self: center;
        border-radius: 50px;
        background-color: #FEF0EF;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }

    .successImage {
        display: flex;
        height: 100px;
        width: 100px;
        align-self: center;
        border-radius: 50px;
        background-color: #D1FADF;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }

    .payment-content-title {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .payment-content-subtitle {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #64748B;
    }

    .payment-type-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 40px;
        background: #F1F5F9;
        border-radius: 4px;
        margin-left: 16px;
    }

    .detailText {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #64748B;
        text-transform: capitalize;
    }

    .respText {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #121A2D;
        text-transform: capitalize;
    }

    .amountText {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #3762FB;
    }

    .totalText {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #3762FB;
    }

    .accountDetails {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #64748B;
    }

    .rowContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .payment-confirmation {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #121A2D;
    }

    .payment-confirmation-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 16px;
    }

    .payment-confirmation-details>p {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #121A2D;
        text-transform: capitalize;
    }

    .payment-options-container {
        max-height: 45vh;
    }

    .payment-limit-container {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        padding: 8px 20px;
    }
    .credit-display-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;
        border-bottom: 1px solid #CBD5E1;
        margin-bottom: 16px;
    }
    .amount-label-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #64748B;
    }
    .amount-text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #121A2D;
    }
    .use-credit-text {
        display: flex;
        flex-direction: row;
        width: 130px;
        height: 22px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #121A2D;
    }
    .use-credit-text > span {
        color: #3762FB;
        margin-left: 4px;
        margin-right: 4px;
    }
    .patial-payment-label {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #121A2D;
        margin-bottom: 6px;
    }
    .fees-label {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: #64748B;
        margin-bottom: 4px;
    }
    .credit-card-fees {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #3762FB;
    }
    .confirm-credit {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: #F04438;
    }
    .total-pay-label {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #3762FB;
    }
    .create-release-info {
        display: flex;
        flex-direction: row;
        background-color: #B9E6FE;
        padding: 16px;
        border-radius: 8px;
        margin-top: 12px;
    }
    .create-release-info-text {
        color:  #065986;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.28px;
    }
    .create-release-info-text > span {
        font-weight: 600;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}