.input-wrapper {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    // height: 100%;
    border: 1px solid #E4EAF1;
    border-radius: 8px;
    justify-content: center;
    padding: 10px 8px;

    .input-section {


        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.8em;
        // height: 100%;


        .icon {
            margin-right: 5px;
        }

        .field {
            width: 100%;
            position: relative;
            border: 0px;
            resize: none;

            &:focus {
                outline: none;
            }
        }

    }



    .error-text {
        margin: 0;
        margin-top: 3px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.02em;
        padding: 0;
        color: red;
        font-size: 0.6em;
        position: absolute;
        bottom: -16px;
    }
}