@import "../../../../styleHelpers/colors";

.title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 24px;
}

.processing-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 110px;
    height: 28px;
    background: #E8EDFF;
    border-radius: 34px;
}

.processing-status-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $COLOR_PRIMARY_500;
    margin-left: 6px;
    text-transform: capitalize;
}

.success-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 110px;
    height: 28px;
    background: $COLOR_SUCCESS_100;
    border-radius: 34px;
}

.success-status-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $COLOR_SUCCESS_500;
    margin-left: 6px;
    text-transform: capitalize;
}

.failed-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 110px;
    height: 28px;
    background: $COLOR_ERROR_100;
    border-radius: 34px;
}

.failed-status-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $COLOR_ERROR_500;
    margin-left: 6px;
    text-transform: capitalize;
}

.letter-container {
    display: flex;
    width: 40px;
    height: 40px;
    background: #E8EDFF;
    border-radius: 38px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    // font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #3762FB;
}

.action-type-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #64748B;
    margin-left: 4px;
}

.name-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121A2D;
    text-transform: capitalize;
}

.payment-type-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    height: 32px;
    background: #F1F5F9;
    border-radius: 4px;
    align-self: center;
    margin-top: 4px;
    margin-right: 12px;
}