.relative {
  position: relative;
}

.circular-progress-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0; /* remove white-space below svg */
}

.circular-progress-svg-container > svg {
  transform: rotate(-90deg);
}
