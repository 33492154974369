.lien-waiver-label {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #64748B;
    margin-bottom: 4px;
}

.lein-waiver-doc-details {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #121A2D;
}

.signature-image-container {
    display: flex;
    height: 40px;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    flex-direction: row;
}

.signature-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.lien-waiver-link {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #3762FB;
    margin-bottom: 4px;
    text-decoration: underline;
    word-break: break-all;
}